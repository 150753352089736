import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';

const UspechInspirace = () => (
	<div>
		<Helmet title="Balení do sáčků, technologie výroby" />
		<div className="first_container">
			<div className="story">
				<div className="breadcrumb">
					<h1>Balení do sáčků, technologie výroby</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Technologie a výroba</li>
					</ul>
				</div>
				<p>Jsme si vědomi své zodpovědnosti vůči životnímu prostředí i vůči
	společnosti a tato zodpovědnost je středobodem veškerého našeho konání.
	Proto bez ustání apelujeme na své kolegy i partnery, aby dodržovali
	nejvyšší možné standardy, a to jak na mezinárodní, tak regionální a
	místní úrovni. Abychom dostáli svým závazkům v oblasti životního
	prostředí a společnosti, vytyčili jsme si několik ambiciózních cílů
	v klíčových oblastech, jako je vývoj, výroba a přeprava.</p>

				<p>Společnost bestpack.cz jako  klíčový hráč na trhu, který je na
	vedoucí pozici  v oboru balení a plnění, a jiných specialit, si
	uvědomuje, že bezpečnost a ochrana zdraví při práci je trvalou součástí
	jejích činností a proto se zavazuje: Provádět pravidelnou identifikaci a
	hodnocení pracovních rizik za účelem předcházení vzniku nežádoucích
	událostí a přijímání opatření k ochraně zdraví zaměstnanců a
	zlepšování pracovního prostředí a pracovních podmínek na všech
	pracovištích.</p>

				<p>Neustále zlepšovat systém bezpečnosti a ochrany zdraví při práci.
	Dodržovat právní a jiné předpisy, včetně mezinárodně uznávaných
	dokumentů jako jsou HACCAP. Posilovat vědomí zaměstnanců za ochranu
	vlastního zdraví a zdraví svých kolegů, klást důraz na osobní
	odpovědnost za prosazování a dodržování právních a jiných předpisů
	při práci a to včetně vedoucích zaměstnanců na všech úrovních
	vedení.</p>

				<h3>S nápadem, realizovat jej a uspět, je pro nás inspirací a výzvou
	k další práci.</h3>

				<ul>
					<li>Neustále inovujeme a renovujeme naše výrobky a zajištujeme jejich
		nejvyšší kvalitu.</li>

					<li>Trvale používáme nejlepší postupy a procesy při úsilí o dokonalost
		v podnikání.</li>

					<li>Poskytujeme spolehlivé a včasné služby našim zákazníkům a
		uspokojujeme spotřebitelskou poptávku po nejlepších vlastnostech našich
		výrobků.</li>

					<li>Pečujeme o motivaci a profesionální růst našich zaměstnanců.</li>

					<li>Podnikáme společensky odpovědným a udržitelným způsobem; dodržujeme
		nejpřísnější právní a průmyslové standardy v oblasti bezpečnosti
		potravin, bezpečnosti práce a ochrany životního prostředí.</li>
				</ul>

				<br />

				<p>Sdělovat Politiku bezpečnosti a ochrany zdraví při práci všem
	obchodním partnerům, dodavatelům, návštěvníkům naší společnosti.
	Vyžadovat plnění principů politiky bezpečnosti a ochrany zdraví při
	práci od všech obchodních partnerů a návštěvníků nacházejících se
	v areálu firmy</p>

				<p>Bestpack patří mezi subjekty, které se již několik let významnou měrou
	podílejí na budování trhu v oboru balení a plnění na zakázku.
	Úspěchy, kterých jsme dosáhli jsou podloženy cílevědomostí, tvrdou
	prací, ale i potřebnou dávkou štěstí. Naše firemní kultura postavená
	na pevných morálních principech, spolu s loajalitou našich zaměstnanců
	z nás dělají spolehlivého a osvědčeného partnera. Důkazem toho je, že
	i v dnešním globalizovaném světě může vyrůstat firma, která se bez
	zahraničního kapitálu a cizího know-how dokáže prosadit. Poznání, že je
	možné přijít s nápadem, realizovat jej a uspět, je pro nás inspirací a
	výzvou k další práci.</p>

				<p>Neustále inovujeme a renovujeme naše výrobky a zajištujeme jejich
	nejvyšší kvalitu. Trvale používáme nejlepší postupy a procesy při
	úsilí o dokonalost v podnikání. Poskytujeme spolehlivé a včasné služby
	našim zákazníkům a uspokojujeme spotřebitelskou poptávku po nejlepších
	vlastnostech našich výrobků. Pečujeme o motivaci a profesionální růst
	našich zaměstnanců.</p>

				<p>Podnikáme společensky odpovědným a udržitelným způsobem; dodržujeme
	nejpřísnější právní a průmyslové standardy v oblasti bezpečnosti
	potravin, bezpečnosti práce a ochrany životního prostředí.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default UspechInspirace;